import { SectionContentList } from '@sbercloud/uikit-product-site-section';

import { useLayoutType } from '../../../hooks/useLayoutType';
import { DemoSectionDefaultProps, DemoSectionWithListProps } from '../types';

const hasControls = (video: DemoSectionDefaultProps['video']) => {
  if (typeof video === 'string') {
    return true;
  } else {
    return video?.controls === undefined ? true : video.controls;
  }
};

const getVideoProps = (video: DemoSectionDefaultProps['video']) => {
  if (!video) {
    return null;
  }

  const controls = hasControls(video);

  if (typeof video === 'string') {
    return {
      link: video,
      controls,
    };
  }

  if (typeof video === 'object') {
    return {
      link: video.link,
      previewImage: video?.previewImage,
      controls,
      loop: !controls,
      muted: !controls,
    };
  }

  return null;
};

export function DefaultDemoSectionWithList({
  id,
  title,
  image,
  richText,
  video,
  items,
  contentLeft,
  className,
}: Omit<DemoSectionWithListProps, 'tabs' | 'videos' | 'images'> & {
  title: string;
  id?: string;
}) {
  const videoProps = getVideoProps(video);
  const layoutType = useLayoutType();
  const videoLink = videoProps?.link;

  return (
    <SectionContentList
      id={id}
      layoutType={layoutType}
      className={className}
      image={
        (image
          ? { src: image, alt: title }
          : undefined) as typeof videoLink extends string ? never : undefined
      }
      video={
        videoLink
          ? {
              ...videoProps,
              src: videoLink,
              poster: videoProps.previewImage ?? '',
            }
          : undefined
      }
      title={title}
      description={richText}
      contentPosition={contentLeft ? 'left' : 'right'}
      items={items}
    />
  );
}

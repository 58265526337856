import { DefaultDemoSection } from './DefaultDemoSection';
import { DefaultDemoSectionWithList } from './DefaultDemoSectionWithList';
import { DemoSectionWithTabs } from './DemoSectionWithTabs';
import { DemoSectionProps, Type } from './types';

export function DemoSection({
  id,
  title,
  image,
  images,
  richText,
  video,
  videos,
  richTexts,
  tabs,
  button,
  items,
  contentLeft,
  type = Type.Default,
  className = '',
}: DemoSectionProps) {
  if (type === Type.Tabs) {
    return (
      <DemoSectionWithTabs
        title={title}
        videos={videos}
        images={images}
        richTexts={richTexts}
        tabs={tabs ?? []}
        id={id}
        button={button}
        className={className}
      />
    );
  }

  if (type === Type.List) {
    return (
      <DefaultDemoSectionWithList
        title={title}
        image={image}
        richText={richText}
        video={video}
        items={items}
        id={id}
        contentLeft={contentLeft}
        className={className}
      />
    );
  }

  return (
    <DefaultDemoSection
      title={title}
      image={image}
      richText={richText ?? ''}
      video={video}
      button={button}
      id={id}
      contentLeft={contentLeft}
      className={className}
    />
  );
}

DemoSection.type = Type;

import { RichText } from '@sbercloud/uikit-product-site-rich-text';
import { Typography } from '@snack-uikit/typography';
import { ReviewProps } from 'src/components/Carousel/types';
import { useLayoutType } from 'src/hooks/useLayoutType';

import s from './Review.module.scss';

export function ReviewSlide({
  title,
  description,
  text,
}: Omit<ReviewProps, 'id'>) {
  const layoutType = useLayoutType();

  return (
    <div className={s.content} data-layout-type={layoutType}>
      <div className={s.author} data-layout-type={layoutType}>
        <Typography.SansTitleL data-test-id="slide_title">
          {title}
        </Typography.SansTitleL>
        {description && (
          <Typography.SansBodyL>
            <RichText richText={description} />
          </Typography.SansBodyL>
        )}
      </div>

      <Typography.SansBodyL>
        <RichText richText={text} />
      </Typography.SansBodyL>
    </div>
  );
}

import { Wrapper } from 'src/components/Wrapper';
import { consoleRedirectUrl } from 'src/global';
import { useFormatConsoleUrl } from 'src/hooks/useFormatConsoleUrl';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Button } from 'src/uikit/Button';

import styles from './styles.module.scss';

export function CustomMarketingSlide() {
  const {
    windowWidth,
    breakpoints: { SM, MD, XLG },
  } = useWindowWidth();

  const consoleLink = useFormatConsoleUrl(
    consoleRedirectUrl,
    'banner',
    'Попробовать бесплатно',
  );

  return (
    <div className={styles.root} style={{ backgroundColor: '#26d07c' }}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.content}>
            <span className={styles.title}>
              Есть где
              <br /> развернуться
            </span>
            <div className={styles.description}>
              Делаем доступ к облакам и AI простым&nbsp;и&nbsp;удобным
            </div>

            <Button
              className={styles.button}
              link={consoleLink}
              color={Button.colors.Yellow}
              analyticsOptions={{
                action: consoleLink,
                clickZone: 'body',
                clickElement: 'button',
                clickContent: 'Попробовать бесплатно',
                uniqueId: `main-custom-marketing-slide-button`,
                transitionType: 'inside-link',
              }}
            >
              Попробовать бесплатно
            </Button>
          </div>

          {windowWidth && windowWidth > SM && (
            <picture className={styles.imageWrapper}>
              <source
                srcSet="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_first_tablet.webp"
                media={`(max-width: ${MD}px)`}
              />
              <source
                srcSet="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_first_desktop.webp"
                media={`(max-width: ${XLG}px)`}
              />
              <img
                loading="lazy"
                src="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_first_desktop.webp"
                className={styles.image}
                alt="main slide"
              />
            </picture>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

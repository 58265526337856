import { Color } from 'src/uikit/Button/constants';

import { CarouselBase } from '../CarouselBase';
import { MainSlide, MainSlideProps } from '../CarouselBase/slides/MainSlide';
import { CustomGoCloud2025Slide } from '../CarouselBase/slides/MainSlide/CustomSlides/CustomGoCloud2025Slide';
import { CustomMarketingSlide } from '../CarouselBase/slides/MainSlide/CustomSlides/CustomMarketingSlide';
import s from './MainCarousel.module.scss';

interface MainCarouselProps {
  slides: MainSlideProps[];
}

type CustomSlides = {
  slide: JSX.Element;
  order?: string;
};

function sortSlidesByOrder(
  customSlides: CustomSlides[],
  dataSlides: JSX.Element[],
): JSX.Element[] {
  if (customSlides.length === 0) {
    return dataSlides;
  }

  const isOrder = customSlides.every((item) => Boolean(item.order));

  if (!isOrder) {
    const customSlidesData = customSlides.map((item) => item.slide);
    return customSlidesData.concat(dataSlides);
  }

  const resultArray = [...dataSlides],
    copyCustomSlides = [...customSlides];

  copyCustomSlides.sort((a, b) => {
    if (a.order && b.order) {
      return parseInt(a.order, 10) - parseInt(b.order, 10);
    }

    return 0;
  });

  copyCustomSlides.forEach((item) => {
    if (item.order) {
      resultArray.splice(parseInt(item.order, 10), 0, item.slide);
    }
  });

  return resultArray;
}

export function MainCarousel({ slides }: MainCarouselProps) {
  const customSlides: CustomSlides[] = [
    {
      slide: <CustomMarketingSlide key={'custom-marketing-slide'} />,
      order: '0',
    },
    {
      slide: <CustomGoCloud2025Slide key={'custom-gocloud2025-slide'} />,
      order: '2',
    },
  ];

  const slidesData = slides.map(
    ({ id, title, description, image, button, theme, videoBg, imageBg }) => {
      return (
        <MainSlide
          key={`${title}_${id}`}
          id={id}
          title={title}
          description={description}
          image={image}
          button={button}
          theme={theme}
          videoBg={videoBg}
          imageBg={imageBg}
        />
      );
    },
  );

  const allSlides = [...sortSlidesByOrder(customSlides, slidesData)];

  return (
    <section className={s.root} data-qa="main-carousel">
      <CarouselBase
        autoPlay
        interval={5000}
        slidesCount={slides.length}
        withPagination
        loop
        paginationBulletClassName={s.bullet}
      >
        {allSlides}
      </CarouselBase>
    </section>
  );
}

MainCarousel.buttonColor = Color;

import {
  SectionCaseCarousel,
  SectionCaseCarouselProps,
} from '@sbercloud/uikit-product-site-section';
import { useRouter } from 'next/navigation';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useLayoutType } from 'src/hooks/useLayoutType';

import { ClientCasesProps } from '../CarouselBase/slides/ClientCasesSlide';

export type ClientCasesCarouselProps = {
  id?: string;
  slides: ClientCasesProps[];
  title?: string;
  backgroundColor?: SectionCaseCarouselProps['backgroundColor'];
  moreButton?: {
    href?: string;
    label?: string;
    page: string;
  };
};

export function ClientCasesCarousel({
  id,
  slides,
  title,
  backgroundColor,
  moreButton,
}: ClientCasesCarouselProps) {
  const layoutType = useLayoutType();
  const { clickAnalytics } = useAnalytics();
  const router = useRouter();

  const moreButtonProps = moreButton
    ? {
        label: 'Смотреть все кейсы',
        href: '/cases',
        ...moreButton,
      }
    : undefined;

  return (
    <SectionCaseCarousel
      id={id}
      title={title}
      layoutType={layoutType}
      backgroundColor={backgroundColor ?? 'neutral-background'}
      items={slides.map((slide) => ({
        href: slide.link,
        description: slide.text ?? '',
        logo: {
          alt: slide.text ?? '',
          src: slide.image ?? '',
        },
      }))}
      moreButton={
        moreButtonProps
          ? {
              label: moreButtonProps.label,
              href: moreButtonProps.href,
              'data-test-id': `show-more-btn-${moreButtonProps.page}-page`,
              onClick: (e) => {
                e.preventDefault();

                router.push(moreButtonProps.href);

                clickAnalytics({
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'button',
                  clickContent: moreButtonProps.label,
                  uniqueId: `cases-show-more-button-${moreButtonProps.page}`,
                  transitionType: 'inside-link',
                });
              },
            }
          : undefined
      }
    />
  );
}

import { SectionContentTabs } from '@sbercloud/uikit-product-site-section';

import { useAnalytics } from '../../../hooks/useAnalytics';
import { useLayoutType } from '../../../hooks/useLayoutType';
import { DemoSectionWithTabsProps } from '../types';

export function DemoSectionWithTabs({
  title,
  tabs,
  videos,
  images,
  richTexts = [],
  id,
  className = '',
  button,
}: Omit<
  DemoSectionWithTabsProps,
  'image' | 'content' | 'video' | 'contentLeft'
> & {
  title: string;
  id?: string;
}) {
  const layoutType = useLayoutType();

  const { clickAnalytics, videoPlayAnalytics } = useAnalytics();

  const onButtonClick = () => {
    if (button)
      try {
        clickAnalytics({
          action: 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: button.title,
          uniqueId: button.uniqueId,
          transitionType: 'inside-link',
        });

        button.onClick && button.onClick();
      } catch {
        // noop
      }
  };

  return (
    <SectionContentTabs
      id={id}
      className={className}
      layoutType={layoutType}
      title={title}
      button={
        button
          ? {
              label: button.title,
              onClick: onButtonClick,
              href: button.link,
            }
          : undefined
      }
      tabs={tabs.map(({ id, icon, title, isDisabled, slug }) => {
        const video = videos?.find((video) => video.id === id);
        const videoLink = video?.link;
        const image = images?.find((image) => image.id === id);
        const description = richTexts?.find((richText) => richText.id === id);

        return {
          id,
          title,
          icon,
          disabled: isDisabled,
          mediaTitle: image?.title,
          mediaSubtitle: image?.subtitle,
          mediaDescription: description?.richText,
          href: slug,
          image: (image
            ? { src: image.image, alt: image.title }
            : undefined) as typeof videoLink extends string ? never : undefined,

          video: videoLink
            ? {
                src: videoLink,
                poster: video.previewImage ?? '',
                controls: video.controls,
                autoPlay: !video.controls,
                loop: !video.controls,
                muted: !video.controls,
              }
            : undefined,
          onPlay: videoLink
            ? () => {
                videoPlayAnalytics(videoLink);
              }
            : undefined,
        };
      })}
    />
  );
}

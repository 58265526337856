import { SectionContentListProps } from '@sbercloud/uikit-product-site-section';

import { TabType } from '../Tabs';

export enum Type {
  Default = 'default',
  List = 'list',
  Tabs = 'tabs',
}

export type DemoSectionWithTabsProps = {
  tabs: TabType[];
  videos?: {
    link: string;
    id: string;
    previewImage?: string;
    controls?: boolean;
  }[];
  images?: {
    image: string;
    id: string;
    title?: string;
    subtitle?: string;
  }[];
  richTexts?: {
    id: string;
    richText: string;
  }[];
  className?: string;
  button?: {
    onClick?: () => void;
    link?: string;
    title: string;
    uniqueId: string;
  };

  image?: undefined;
  richText?: undefined;
  video?: undefined;
  contentLeft?: undefined;
  items?: undefined;
};

export type DemoSectionWithListProps = {
  image?: string;
  video?:
    | {
        controls?: boolean;
        link: string;
        previewImage?: string;
      }
    | string;
  contentLeft?: boolean;
  items: SectionContentListProps['items'];
  className?: string;

  tabs?: undefined;
  videos?: undefined;
  images?: undefined;
  richTexts?: never;
  richText?: string;
  button?: undefined;
};

export type DemoSectionDefaultProps = {
  richText: string;
  image?: string;
  video?:
    | {
        controls?: boolean;
        link: string;
        previewImage?: string;
      }
    | string;
  contentLeft?: boolean;
  button?: {
    onClick?: () => void;
    link?: string;
    title: string;
    uniqueId: string;
  };
  className?: string;

  tabs?: undefined;
  videos?: undefined;
  images?: undefined;
  richTexts?: undefined;
  items?: undefined;
};

export type DemoSectionProps = {
  title: string;
  type?: Type;
  id?: string;
} & (
  | DemoSectionDefaultProps
  | DemoSectionWithListProps
  | DemoSectionWithTabsProps
);

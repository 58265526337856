import { DemoSectionDefaultProps } from './types';

const hasControls = (video: DemoSectionDefaultProps['video']) => {
  if (typeof video === 'string') {
    return true;
  } else {
    return video?.controls === undefined ? true : video.controls;
  }
};

export const getVideoProps = (video: DemoSectionDefaultProps['video']) => {
  if (!video) {
    return null;
  }

  const controls = hasControls(video);

  if (typeof video === 'string') {
    return {
      link: video,
      controls,
    };
  }

  if (typeof video === 'object') {
    return {
      link: video.link,
      previewImage: video?.previewImage,
      controls,
      loop: !controls,
      muted: !controls,
      autoPlay: !controls,
    };
  }

  return null;
};

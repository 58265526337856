import cn from 'classnames';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { Button } from 'src/uikit/Button';

import styles from './styles.module.scss';

export function CustomGoCloud2025Slide() {
  const {
    windowWidth,
    breakpoints: { SM, MD, XLG },
  } = useWindowWidth();

  return (
    <div
      className={cn(styles.root, styles.whiteText)}
      style={{ backgroundColor: '#222222' }}
    >
      <Wrapper className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.content}>
            <span className={styles.title}>
              Масштабная конференция про&nbsp;облака и AI
            </span>
            <div className={styles.description}>
              30+ докладов про AI&ML, новые сервисы, сценарии работы в облаке и
              демо облачных платформ
            </div>

            <Button
              className={styles.button}
              link="/gocloud"
              color={Button.colors.Yellow}
              analyticsOptions={{
                action: '/gocloud',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: 'принять участие',
                uniqueId: `main-custom-gocloud2025-slide-button`,
                transitionType: 'inside-link',
              }}
            >
              принять участие
            </Button>
          </div>

          {windowWidth && windowWidth > SM && (
            <picture className={styles.imageWrapper}>
              <source
                srcSet="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_golcoud_tablet.webp"
                media={`(max-width: ${MD}px)`}
              />
              <source
                srcSet="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_golcoud_desktop.webp"
                media={`(max-width: ${XLG}px)`}
              />
              <img
                loading="lazy"
                src="https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_golcoud_desktop.webp"
                className={styles.image}
                alt="main slide"
              />
            </picture>
          )}
        </div>
      </Wrapper>
    </div>
  );
}

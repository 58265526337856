import { SectionContent } from '@sbercloud/uikit-product-site-section';

import { useAnalytics } from '../../../hooks/useAnalytics';
import { useLayoutType } from '../../../hooks/useLayoutType';
import { DemoSectionDefaultProps } from '../types';
import { getVideoProps } from '../utils';

export function DefaultDemoSection({
  title,
  image,
  richText,
  video,
  button,
  id,
  contentLeft,
  className,
}: Omit<DemoSectionDefaultProps, 'tabs' | 'videos' | 'images'> & {
  title: string;
  id?: string;
}) {
  const videoProps = getVideoProps(video);
  const layoutType = useLayoutType();

  const { clickAnalytics } = useAnalytics();

  const onButtonClick = () => {
    if (button)
      try {
        clickAnalytics({
          action: 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: button.title,
          uniqueId: `demo-section-${button.uniqueId}`,
          transitionType: 'inside-link',
        });

        button.onClick && button.onClick();
      } catch {
        // noop
      }
  };

  const videoLink = videoProps?.link;

  return (
    <SectionContent
      backgroundColor="neutral-background1-level"
      id={id}
      layoutType={layoutType}
      className={className}
      image={
        (image
          ? { src: image, alt: title }
          : undefined) as typeof videoLink extends string ? never : undefined
      }
      video={
        videoLink
          ? {
              ...videoProps,
              src: videoLink,
              poster: videoProps.previewImage ?? '',
            }
          : undefined
      }
      title={title}
      description={richText}
      contentPosition={contentLeft ? 'left' : 'right'}
      button={
        button
          ? {
              label: button.title,
              onClick: onButtonClick,
              href: button.link,
            }
          : undefined
      }
    />
  );
}

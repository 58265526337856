import cn from 'classnames';
import { ArrowOut } from 'src/uikit/icons/ContentIcons/General/ArrowOut';
import { Link } from 'src/uikit/Link';

import s from './ClientCasesSlide.module.scss';

export interface ClientCasesProps {
  text?: string;
  image?: string;
  link?: string;
}

export function ClientCasesSlide({
  text = '',
  image = '',
  link = '',
}: ClientCasesProps) {
  const imageSrc = image
    ? image
    : 'https://cdn.cloud.ru/backend/cases/cases_logo.svg?v=1';

  return (
    <Link
      className={cn('link', s.root)}
      href={link}
      id={`caseslide-link-${link}`}
      analyticsOptions={{
        action: link,
        clickZone: 'body',
        clickElement: 'cardclick',
        clickContent: text,
        uniqueId: `caseslide-link-${link}`,
        transitionType: 'inside-link',
      }}
    >
      <div className={s.imageWrapper}>
        <img width={138} height={72} src={imageSrc} alt="case" />
      </div>
      <div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />

      <ArrowOut className={s.icon} />
    </Link>
  );
}
